import React from 'react'
import Topbars from '../../Topbars/Topbars'
import Listeclients from './ListeClients/Listeclient'


export default function GestionClient() {
  return (
    <div>
      <Topbars/>
    <  Listeclients/>
    </div>
  )
}
