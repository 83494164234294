export const models = {
  SidebarModelOne: `
  <script>
  $(function(){
    $(".sidebar-menu-toggle").click(function(){
 
       $(".sidebar-menu").addClass("active");
    })
    $(".sidebar-menu-close").click(function(){
       $(".sidebar-menu").removeClass("active");
    })
 })
  </script>
<div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-4d13e102-b0c4-486b-a026-b11151b642d9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
  <div class="gjs-cell" id="i2di">
  <sapn class="sidebar-menu-close" >
    <i id="icon-3623c40e-da48-45e3-8765-8139c77729a2" class="icon-gjs fas fa-times ">
    </i>
  </div>
  </sapn>
</div>
<div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-a4078714-6caf-453c-94ff-58d322768aa9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
  <div class="gjs-cell" id="ixfbf">
    <div data-model="vertical" data-bg="rgb(0 0 0 / 0%)" data-seperator-color="#000" data-raduis="{&quot;topLeft&quot;:&quot;0&quot;,&quot;topRight&quot;:&quot;0&quot;,&quot;bottomLeft&quot;:&quot;0&quot;,&quot;bottomRight&quot;:&quot;0&quot;}" data-padding="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;30&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;30&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-text="rgb(0, 0, 0,1)" data-survol-bg="#000000" data-bg-submenu="#eeeeee" data-padding-submenu="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;15&quot;,&quot;right&quot;:&quot;15&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin-submenu="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-submenu-text="rgb(0, 0, 0,1)" data-survol-submenu-bg="#000000" data-style="stroke-effect" data-seperator="" data-align="center" data-logo-width="200px" data-font="unset" data-variant="normal" data-size="{&quot;desktop&quot;:&quot;20&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-color="#000000" data-submenu-font="unset" data-submenu-variant="normal" data-submenu-size="{&quot;desktop&quot;:&quot;16&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-submenu-color="#000000" data-submenu-align="left" id="irx2f" class="gjs-navigation stroke-effect ">
      <nav class="gjs-nav d-flex align-items-center">
        <ul class="navbar-nav flex-column">
          <li class="nav-item">
            <a target="_self" id="653633c9411b2edaa44c5679" href="/" title="Accueil" class="active"> Accueil</a>
          </li>
          <li class="nav-item dropdown"> 
            <a href="/qsdsqdsqd" target="_blank" id="sub-65377667411b2edaa44c6f84" aria-expanded="false" role="button" class="dropdown-toggle">qsdsqdsqd</a>
            <ul data-bs-popper="static" aria-labelledby="sub-65377667411b2edaa44c6f84" class="dropdown-menu submenu-one border-0">
              <li class="nav-item dropdown dropend">
                <a target="_self" href="/dsfdsf" id="sub-6537766a411b2edaa44c6f99" aria-expanded="false" role="button" class="dropdown-item dropdown-toggle submenu">dsfdsf qsdsqdsqdsq sqdsqdsqqsdsqdsq</a>
                <ul data-bs-popper="static" aria-labelledby="sub-6537766a411b2edaa44c6f99" class="dropdown-menu submenu-two border-0">
                  <li class="nav-item">
                    <a href="/sdsqdsdqd" title="sdsqdsdqd" class="dropdown-item"> 
                      sdsqdsdqd </a> 
                  </li>
                </ul>
              </li> 
              <li class="nav-item">
                <a target="_self" href="/sdfdsfsd" title="sdfdsfsd" class="dropdown-item"> 
                  sdfdsfsd </a> 
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

    <style>
    #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a{
      border-top-left-radius:0%;
      border-top-right-radius:0%;
      border-bottom-left-radius:0%;
      border-bottom-right-radius:0%;
      padding-top:15px;
      padding-bottom:15px;
      padding-right:30px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:30px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:20px;
      line-height:20px;
      padding-left:0px;
    }
    #ibo5 ul li a:hover{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(238, 238, 238);
    }
    #ibo5 ul.dropdown-menu li a{
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:16px;
      line-height:16px;
    }
    #ibo5 ul.dropdown-menu li a:hover{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #i2di{
      width:50%;
      vertical-align:middle;
      padding-top:0px;
      padding-right:0px;
      padding-bottom:0px;
      padding-left:0px;
      text-align:right;
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    .fb-square{
      fill:rgb(25, 118, 210);
    }
    .fb-font{
      fill:rgb(255, 255, 255);
    }
    .instagram-font{
      fill:rgb(255, 255, 255);
    }
    .instagram-square{
      fill:url("#radial-gradient");
    }
    #irx2f{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:white;
      display:flex !important;
      justify-content:center !important;
    }
    #ixfbf{
      width:50%;
      vertical-align:middle;
    }
    #ifgvt{
      width:300px;
    }
    #iyf62{
      position:relative;
      width:250px;
      height:auto;
    }
    #ila4r{
      font-size:20px;
      font-weight:700;
      margin-top:20px;
      margin-right:0px;
      margin-bottom:20px;
      margin-left:0px;
      line-height:25px;
    }
    #igl2fw{
      justify-content:flex-start;
      display:flex !important;
    }
    #igl2fw .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #igl2fw .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #igkhga{
      width:50%;
      vertical-align:middle;
    }
    #icon-3623c40e-da48-45e3-8765-8139c77729a2{
      font-size:45px;
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a{
      border-top-left-radius:0%;
      border-top-right-radius:0%;
      border-bottom-left-radius:0%;
      border-bottom-right-radius:0%;
      padding-top:15px;
      padding-bottom:15px;
      padding-right:30px;
      padding-left:0px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:30px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:20px;
      line-height:20px;
    }
    #irx2f ul li a:hover{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(238, 238, 238);
    }
    #irx2f ul.dropdown-menu li a{
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
    }
    #irx2f ul.dropdown-menu li a:hover{
      color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active{
      color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    @media (min-width: 768px) and (max-width: 991px){
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #irx2f ul li a{
        font-size:14px;
        line-height:14px;
      }
    }
    @media (max-width: 767px){
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul.dropdown-menu li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #irx2f ul li a{
        font-size:14px;
        line-height:14px;
      }
    }
    
    </style>
`,
  SidebarModelTwo: `
  <script>
  $(function(){
    $(".sidebar-menu-toggle").click(function(){
 
       $(".sidebar-menu").addClass("active");
    })
    $(".sidebar-menu-close").click(function(){
       $(".sidebar-menu").removeClass("active");
    })
 })
  </script>
<div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-4d13e102-b0c4-486b-a026-b11151b642d9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
  <div class="gjs-cell" id="i2di">
  <span class="sidebar-menu-close">
    <i id="icon-3623c40e-da48-45e3-8765-8139c77729a2" class="icon-gjs fas fa-times ">
    </i>
  </span>
  </div>
</div>
<div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-a4078714-6caf-453c-94ff-58d322768aa9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
  <div class="gjs-cell" id="ixfbf">
    <div data-model="vertical" data-bg="rgb(0 0 0 / 0%)" data-seperator-color="#000" data-raduis="{&quot;topLeft&quot;:&quot;0&quot;,&quot;topRight&quot;:&quot;0&quot;,&quot;bottomLeft&quot;:&quot;0&quot;,&quot;bottomRight&quot;:&quot;0&quot;}" data-padding="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;30&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;30&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-text="rgb(0, 0, 0,1)" data-survol-bg="#000000" data-bg-submenu="#eeeeee" data-padding-submenu="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;15&quot;,&quot;right&quot;:&quot;15&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin-submenu="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-submenu-text="rgb(0, 0, 0,1)" data-survol-submenu-bg="#000000" data-style="stroke-effect" data-seperator="" data-align="center" data-logo-width="200px" data-font="unset" data-variant="normal" data-size="{&quot;desktop&quot;:&quot;20&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-color="#000000" data-submenu-font="unset" data-submenu-variant="normal" data-submenu-size="{&quot;desktop&quot;:&quot;16&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-submenu-color="#000000" data-submenu-align="left" id="irx2f" class="gjs-navigation stroke-effect ">
      <nav class="gjs-nav d-flex align-items-center">
        <ul class="navbar-nav flex-column">
          <li class="nav-item">
            <a target="_self" id="653633c9411b2edaa44c5679" href="/" title="Accueil" class="active"> Accueil</a>
          </li>
          <li class="nav-item dropdown"> 
            <a href="/qsdsqdsqd" target="_blank" id="sub-65377667411b2edaa44c6f84" aria-expanded="false" role="button" class="dropdown-toggle">qsdsqdsqd</a>
            <ul data-bs-popper="static" aria-labelledby="sub-65377667411b2edaa44c6f84" class="dropdown-menu submenu-one border-0">
              <li class="nav-item dropdown dropend">
                <a target="_self" href="/dsfdsf" id="sub-6537766a411b2edaa44c6f99" aria-expanded="false" role="button" class="dropdown-item dropdown-toggle submenu">dsfdsf qsdsqdsqdsq sqdsqdsqqsdsqdsq</a>
                <ul data-bs-popper="static" aria-labelledby="sub-6537766a411b2edaa44c6f99" class="dropdown-menu submenu-two border-0">
                  <li class="nav-item">
                    <a href="/sdsqdsdqd" title="sdsqdsdqd" class="dropdown-item"> 
                      sdsqdsdqd </a> 
                  </li>
                </ul>
              </li> 
              <li class="nav-item">
                <a target="_self" href="/sdfdsfsd" title="sdfdsfsd" class="dropdown-item"> 
                  sdfdsfsd </a> 
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

    <style>
    #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a{
      border-top-left-radius:0%;
      border-top-right-radius:0%;
      border-bottom-left-radius:0%;
      border-bottom-right-radius:0%;
      padding-top:15px;
      padding-bottom:15px;
      padding-right:30px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:30px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:20px;
      line-height:20px;
      padding-left:0px;
    }
    #ibo5 ul li a:hover{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(238, 238, 238);
    }
    #ibo5 ul.dropdown-menu li a{
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:16px;
      line-height:16px;
    }
    #ibo5 ul.dropdown-menu li a:hover{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #i2di{
      width:50%;
      vertical-align:middle;
      padding-top:0px;
      padding-right:0px;
      padding-bottom:0px;
      padding-left:0px;
      text-align:right;
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    .fb-square{
      fill:rgb(25, 118, 210);
    }
    .fb-font{
      fill:rgb(255, 255, 255);
    }
    .instagram-font{
      fill:rgb(255, 255, 255);
    }
    .instagram-square{
      fill:url("#radial-gradient");
    }
    #irx2f{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:white;
      display:flex !important;
      justify-content:center !important;
    }
    #ixfbf{
      width:50%;
      vertical-align:middle;
    }
    #ifgvt{
      width:300px;
    }
    #iyf62{
      position:relative;
      width:250px;
      height:auto;
    }
    #ila4r{
      font-size:20px;
      font-weight:700;
      margin-top:20px;
      margin-right:0px;
      margin-bottom:20px;
      margin-left:0px;
      line-height:25px;
    }
    #igl2fw{
      justify-content:flex-start;
      display:flex !important;
    }
    #igl2fw .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #igl2fw .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #igkhga{
      width:50%;
      vertical-align:middle;
    }
    #icon-3623c40e-da48-45e3-8765-8139c77729a2{
      font-size:45px;
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a{
      border-top-left-radius:0%;
      border-top-right-radius:0%;
      border-bottom-left-radius:0%;
      border-bottom-right-radius:0%;
      padding-top:15px;
      padding-bottom:15px;
      padding-right:30px;
      padding-left:0px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:30px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:20px;
      line-height:20px;
    }
    #irx2f ul li a:hover{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(238, 238, 238);
    }
    #irx2f ul.dropdown-menu li a{
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
    }
    #irx2f ul.dropdown-menu li a:hover{
      color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active{
      color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    @media (min-width: 768px) and (max-width: 991px){
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #irx2f ul li a{
        font-size:14px;
        line-height:14px;
      }
    }
    @media (max-width: 767px){
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul.dropdown-menu li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #irx2f ul li a{
        font-size:14px;
        line-height:14px;
      }
    }
    
    </style>
`,
  SidebarModelThree: `
  <script>
  $(function(){
    $(".sidebar-menu-toggle").click(function(){
 
       $(".sidebar-menu").addClass("active");
    })
    $(".sidebar-menu-close").click(function(){
       $(".sidebar-menu").removeClass("active");
    })
 })
  </script>
  <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-4d13e102-b0c4-486b-a026-b11151b642d9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
    <div class="gjs-cell" id="i2di">
  <span class="sidebar-menu-close">

      <i id="icon-3623c40e-da48-45e3-8765-8139c77729a2" class="icon-gjs fas fa-times">
      </i>
      </span>
    </div>
  </div>
  <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-a4078714-6caf-453c-94ff-58d322768aa9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
    <div class="gjs-cell" id="ixfbf">
      <div data-model="vertical" data-bg="rgb(0 0 0 / 0%)" data-seperator-color="#000" data-raduis="{&quot;topLeft&quot;:&quot;0&quot;,&quot;topRight&quot;:&quot;0&quot;,&quot;bottomLeft&quot;:&quot;0&quot;,&quot;bottomRight&quot;:&quot;0&quot;}" data-padding="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;30&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;30&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-text="rgb(0, 0, 0,1)" data-survol-bg="#000000" data-bg-submenu="#eeeeee" data-padding-submenu="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;15&quot;,&quot;right&quot;:&quot;15&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin-submenu="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-submenu-text="rgb(0, 0, 0,1)" data-survol-submenu-bg="#000000" data-style="stroke-effect" data-seperator="" data-align="center" data-logo-width="200px" data-font="unset" data-variant="normal" data-size="{&quot;desktop&quot;:&quot;20&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-color="#000000" data-submenu-font="unset" data-submenu-variant="normal" data-submenu-size="{&quot;desktop&quot;:&quot;16&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-submenu-color="#000000" data-submenu-align="left" id="irx2f" class="gjs-navigation stroke-effect ">
        <nav class="gjs-nav d-flex align-items-center">
          <ul class="navbar-nav flex-column">
            <li class="nav-item">
              <a target="_self" id="653633c9411b2edaa44c5679" href="/" title="Accueil" class="active"> Accueil</a>
            </li>
            <li class="nav-item dropdown"> 
              <a href="/qsdsqdsqd" target="_blank" id="sub-65377667411b2edaa44c6f84" aria-expanded="false" role="button" class="dropdown-toggle">qsdsqdsqd</a>
              <ul data-bs-popper="static" aria-labelledby="sub-65377667411b2edaa44c6f84" class="dropdown-menu submenu-one border-0">
                <li class="nav-item dropdown dropend">
                  <a target="_self" href="/dsfdsf" id="sub-6537766a411b2edaa44c6f99" aria-expanded="false" role="button" class="dropdown-item dropdown-toggle submenu">dsfdsf qsdsqdsqdsq sqdsqdsqqsdsqdsq</a>
                  <ul data-bs-popper="static" aria-labelledby="sub-6537766a411b2edaa44c6f99" class="dropdown-menu submenu-two border-0">
                    <li class="nav-item">
                      <a href="/sdsqdsdqd" title="sdsqdsdqd" class="dropdown-item"> 
                        sdsqdsdqd </a> 
                    </li>
                  </ul>
                </li> 
                <li class="nav-item">
                  <a target="_self" href="/sdfdsfsd" title="sdfdsfsd" class="dropdown-item"> 
                    sdfdsfsd </a> 
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="gjs-cell" id="igkhga">
      <a title="test ouss" href="/" id="iyf62" class="logo-gjs"><img id="ifgvt" alt="test ouss" src="https://builder.bforbiz-dev.com/sites/653633c9411b2edaa44c5677/media/logo.png?v=92f483cb-14ac-42a3-afd4-e5b3ddb23bc8" class="logo-img"/></a>
      <p id="ila4r">SUIVEZ-NOUS
      </p>
      <div draggable="true" data-config="10,rotation" data-items="facebook,instagram" data-model-key="model_one" data-item-padding="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;15&quot;,&quot;right&quot;:&quot;15&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-item-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" id="igl2fw" class="social-gjs annimation-undefined ">
        <a draggable="true" href="#" title="facebook" target="_blank"><svg draggable="true" viewBox="0 0 6.82666 6.82666" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g draggable="true">
            <rect draggable="true" height="6.82667" rx="1.45635" ry="1.45635" width="6.82667" class="fb-square">
            </rect>
            <path draggable="true" d="M4.19658 3.51299l-0.546957 0 0 2.00372 -0.82865 0 0 -2.00372 -0.394118 0 0 -0.704406 0.394118 0 0 -0.45565c0,-0.325795 0.154697,-0.83622 0.836161,-0.83622l0.613618 0.00265354 0 0.683575 -0.445252 0c-0.0731693,0 -0.175878,0.0365512 -0.175878,0.19187l0 0.414602 0.619563 0 -0.0726063 0.703575z" class="fb-font">
            </path>
          </g>
          </svg></a>
        <a draggable="true" href="#" title="instagram " target="_blank">
        <svg draggable="true" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs draggable="true">
            <radialGradient draggable="true" cx="-578.95" cy="-837.6" gradientTransform="translate(499.5 629.5) scale(0.75)" gradientUnits="userSpaceOnUse" r="197.06">
              <stop draggable="true" offset="0" stop-color="#f9ed32">
              </stop>
              <stop draggable="true" offset="0.36" stop-color="#ee2a7b">
              </stop>
              <stop draggable="true" offset="0.44" stop-color="#d22a8a">
              </stop>
              <stop draggable="true" offset="0.6" stop-color="#8b2ab2">
              </stop>
              <stop draggable="true" offset="0.83" stop-color="#1b2af0">
              </stop>
              <stop draggable="true" offset="0.88" stop-color="#002aff">
              </stop>
            </radialGradient>
          </defs>
          <title draggable="true">
          </title>
          <g draggable="true" data-name="3-instagram">
            <rect draggable="true" height="64" rx="11.2" ry="11.2" transform="translate(64 64) rotate(180)" width="64" class="instagram-square">
            </rect>
            <path draggable="true" d="M44,56H20A12,12,0,0,1,8,44V20A12,12,0,0,1,20,8H44A12,12,0,0,1,56,20V44A12,12,0,0,1,44,56ZM20,12.8A7.21,7.21,0,0,0,12.8,20V44A7.21,7.21,0,0,0,20,51.2H44A7.21,7.21,0,0,0,51.2,44V20A7.21,7.21,0,0,0,44,12.8Z" class="instagram-font">
            </path>
            <path draggable="true" d="M32,45.6A13.6,13.6,0,1,1,45.6,32,13.61,13.61,0,0,1,32,45.6Zm0-22.4A8.8,8.8,0,1,0,40.8,32,8.81,8.81,0,0,0,32,23.2Z" class="instagram-font">
            </path>
            <circle draggable="true" cx="45.6" cy="19.2" r="2.4" class="instagram-font">
            </circle>
          </g>
          </svg>
          </a>
      </div>
    </div>
  </div>
    <style>
    #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a{
      border-top-left-radius:0%;
      border-top-right-radius:0%;
      border-bottom-left-radius:0%;
      border-bottom-right-radius:0%;
      padding-top:15px;
      padding-bottom:15px;
      padding-right:30px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:30px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:20px;
      line-height:20px;
      padding-left:0px;
    }
    #ibo5 ul li a:hover{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(238, 238, 238);
    }
    #ibo5 ul.dropdown-menu li a{
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:16px;
      line-height:16px;
    }
    #ibo5 ul.dropdown-menu li a:hover{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #i2di{
      width:50%;
      vertical-align:middle;
      padding-top:0px;
      padding-right:0px;
      padding-bottom:0px;
      padding-left:0px;
      text-align:right;
    }
    #ibo5 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ibo5 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    .fb-square{
      fill:rgb(25, 118, 210);
    }
    .fb-font{
      fill:rgb(255, 255, 255);
    }
    .instagram-font{
      fill:rgb(255, 255, 255);
    }
    .instagram-square{
      fill:url("#radial-gradient");
    }
    #irx2f{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:white;
      display:flex !important;
      justify-content:center !important;
    }
    #ixfbf{
      width:50%;
      vertical-align:middle;
    }
    #ifgvt{
      width:300px;
    }
    #iyf62{
      position:relative;
      width:250px;
      height:auto;
    }
    #ila4r{
      font-size:20px;
      font-weight:700;
      margin-top:20px;
      margin-right:0px;
      margin-bottom:20px;
      margin-left:0px;
      line-height:25px;
    }
    #igl2fw{
      justify-content:flex-start;
      display:flex !important;
    }
    #igl2fw .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #igl2fw .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #igkhga{
      width:50%;
      vertical-align:middle;
    }
    #icon-3623c40e-da48-45e3-8765-8139c77729a2{
      font-size:45px;
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a{
      border-top-left-radius:0%;
      border-top-right-radius:0%;
      border-bottom-left-radius:0%;
      border-bottom-right-radius:0%;
      padding-top:15px;
      padding-bottom:15px;
      padding-right:30px;
      padding-left:0px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:30px;
      font-family:unset;
      color:rgb(0, 0, 0);
      text-align:left;
      font-weight:normal;
      font-size:20px;
      line-height:20px;
    }
    #irx2f ul li a:hover{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(238, 238, 238);
    }
    #irx2f ul.dropdown-menu li a{
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
    }
    #irx2f ul.dropdown-menu li a:hover{
      color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active{
      color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li::before{
      color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #irx2f ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    @media (min-width: 768px) and (max-width: 991px){
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #irx2f ul li a{
        font-size:14px;
        line-height:14px;
      }
    }
    @media (max-width: 767px){
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul.dropdown-menu li a{
        font-size:14px;
        line-height:14px;
      }
      #ibo5 ul li a{
        font-size:14px;
        line-height:14px;
      }
      #irx2f ul li a{
        font-size:14px;
        line-height:14px;
      }
    }
    
    </style>
`,
};
