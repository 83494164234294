/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

//import axios from "axios";
//import { v4 as uuidv4 } from "uuid";
import ReactDOM from "react-dom/client";

import ModalCatgegory from "./ModalCatgegory";
export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  //const defaultType = dc.getType("default");
  //const defaultView = defaultType.view;
  //const { Modal } = editor;

  dc.addType("Category", {
    model: {
      defaults: {
        activeOnRender: 1,
        toolbar: [
          // these are the default toolbar elements
          {
            attributes: { class: "fa fa-arrow-up" },
            command: "select-parent",
          },
          {
            attributes: { class: "fa fa-arrows" },
            command: "tlb-move",
          },
          {
            attributes: { class: "fa fa-clone" },
            command: "tlb-clone",
          },
          {
            attributes: { class: "fa fa-trash" },
            command: "tlb-delete",
          },
          // this is my new toolbar element with my custom command
          {
            attributes: { class: "fa fa-gear" },
            command: "CatgegoryConfig",
          },
        ],
      },
      init() {
        this.set("draggable", true);
        this.set("removable", true);
        this.set("copyable", true);
        this.set("droppable", false);
        this.set("selectable", true);
      },
      remove(){
        
      }
    
    },
    view: {
      getAllChildComponents(component) {
        const children = [];

        // Iterate through the children of the component
        component.components().each((childComponent) => {
          children.push(childComponent);

          // Recursively get children of the child component
          const nestedChildren =
            this.model.view.getAllChildComponents(childComponent);
          children.push(...nestedChildren);
        });

        return children;
      },
      init({ el, model }) {
        editor.Commands.add("CatgegoryConfig", {
          run(editor, sender, model) {
            let cmp = editor.getSelected();
            if (!cmp.getClasses().includes("gjs-blog-catgegory")) {
              do {
                cmp = cmp.parent();
              } while (!cmp.getClasses().includes("gjs-blog-catgegory"));
            }

            const container = document.getElementById("Catgegory-modal");
            if (container) {
              container.remove();
            }
            const modalContainer = document
              .createRange()
              .createContextualFragment(`<div id="Catgegory-modal"></div>`);
            document.body.append(modalContainer);
            const root = ReactDOM.createRoot(
              document.getElementById("Catgegory-modal")
            );
            root.render(
              <ModalCatgegory editor={editor} component={cmp} siteId={opts.site} />
            );

            editor.select(cmp);
          },
        });
       // this.listenTo(model, "active", this.doStuff);
      },
      async doStuff() {
        const container = document.getElementById("Catgegory-modal");
        if (container) {
          container.remove();
        }
        const modalContainer = document
          .createRange()
          .createContextualFragment(`<div id="Catgegory-modal"></div>`);
        document.body.append(modalContainer);
        const root = ReactDOM.createRoot(document.getElementById("Catgegory-modal"));

        setTimeout(() => {
          root.render(
            <ModalCatgegory
              editor={editor}
              component={this.model}
              siteId={opts.site}
            />
          );
        }, 500);
      },

      async onRender({ el, model }) {
        let children = this.model.view.getAllChildComponents(this.model);
        children.map((c) => {
          if (!c.getClasses().includes("btn")) {
            c.set("draggable", false);
            c.set("removable", false);
            c.set("copyable", false);
            c.set("resizable", false);
            c.set("droppable", false);
            c.set("editable", false);
            c.set("selectable", false);
            c.set("toolbar", [
              {
                attributes: { class: "fa fa-gear" },
                command: "CatgegoryConfig",
              },
            ]);
          }
        });
        model.set("draggable", true);
        model.set("removable", false);
        model.set("copyable", false);
        model.set("resizable", false);
        model.set("droppable", false);
        model.set("editable", false);
        model.set("selectable", true);

        let attr = model.getAttributes()
        console.log(attr["data-params"]);
        model.components(`
        <style>

        .list-view .article{  display: flex;
         flex-direction: row;
         align-items: start;
         justify-content: flex-start;
         width: 100%;
         padding: 10px;
         border-bottom: 1px solid #dbdbdb;
       }
       .list-view .article .thumbnail{
         width:25%;
         max-width:400px;
         min-width:200px;
         height:200px;
         margin: 0;
         margin-right:20px;
         background-size:cover!important;
         border-radius:7px;
       }
       #${attr['id']} .item-content{
         width:100%;
       }
    
       .item-content h2{
         font-size:30px;
       }
       .item-content .created{
         display:block;
         font-size:14px;
         line-height:16px;
         margin-bottom:10px;
       }
       .item-content p{
         width: 100%; 
         display: -webkit-box;
         -webkit-line-clamp: 3;
         -webkit-box-orient: vertical;
         overflow: hidden;
         max-height:175px
       }
    
       .item-content span{
    display:inline-block;
       
       }
       .item-content a{
       text-decoration :none;
       font-wieght:bold;
       
       }
       
       .pagination {
         display: flex;
         list-style: none;
       }
       
       
       .pagination li {
           margin-top: 0px;
           margin-right: 5px;
           margin-bottom: 0px;
           margin-left: 5px;
           cursor: pointer;
           color: #ffffff;
           border-radius: 5px;
           background: #393939;
           width: 30px;
           height: 30px;
           display: flex;
           justify-content: center;
           align-items: center;
           font-size: 20px;
       }
       
       .pagination li.active {
        
           opacity:0.5
       
       }
       .items-container {
         display: flex;
         flex-wrap: wrap;
       }
       .item {
         border: 1px solid #ccc;
         margin: 5px;
         padding: 10px;
         width: 100px;
         text-align: center;
       }
        </style>
        <div class="input-group mb-3 d-flex justify-content-end">
  <input type="text" class="form-control" id="search" placeholder="Chercher..." aria-label="Chercher..." aria-describedby="button-addon2" >
  <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i class="fas fa-search"></i></button>
</div>
        <div class="item-list list-view" id="list-posts-${attr['id']}"  data-model="${attr['data-model']}" ></div>
        <script>
        $(document).ready(function(){
          getArticle()
          $("#button-addon2").click(function(e){
            
            getArticle($("#search").val())
          })

        })
      function getArticle(search=null){
        console.log(search)
          var params = ${attr["data-params"]}
          var  url ="${process.env.REACT_APP_API_URL}/blogposts/getbycat/${opts.page}"
          $.ajax({
            type: "GET",
            url: url,
            type: 'GET',
            contentType: false,
            processData: false,
          }).done( async function (response) {
          
            $("#list-posts-${attr['id']}").html('')

     
            if(search == null || search==""){
              var posts =response.data

            }else{
            var posts =response.data.filter(p=>p.title.includes(search))
              console.log(posts)
            
            }
            if(posts.length == 0){
              $("#list-posts-${attr['id']}").html('<span>Aucun article trouvé</span>')
              return false ;
            }
            
            $.each(posts, function( index, p ) {
              var dateObject = new Date(p.date_creation);
              var year = dateObject.getFullYear();
              var month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Months are 0-based
              var day = ('0' + dateObject.getDate()).slice(-2);
              var formattedDate = year + '-' + month + '-' + day;
              var htmlContent='<div class="article">'

             
              if(params.postThumb =="true"){
                htmlContent+='<div class="thumbnail" style="background:url(' + p.thumbnail + ');background-size:cover;width:100%;height:200px"></div>'
              }
              htmlContent+= '<div class="item-content">';
              if(params.postCategory =="true"){
                htmlContent+='<span class="category">' +  p.categorie.title + '</span>'
              }
              
              htmlContent+='<h3 class="titleblog">' + p.title + '</h3>'
              if(params.postDate =="true"){
                htmlContent+='<div class="post-meta"><span class="timestamp">' + p.date_creation + '</span></div>'
              }
              
              htmlContent+='<p class="description">' + p.resume + '</p>'
              htmlContent+='<div id="share-'+p._id+'">' + p.resume + '</div>'

              if(params.postBtn =="true"){
                htmlContent+='<div class="bouttonContainer"><a href="/actualites' + p.categorie.url + p.url + '" class="btn btn-primary savoirplus">'+params.postBtnTxt+' →</a>'
              }
              htmlContent+='</div></div></div>'  
              $("#list-posts-${attr['id']}").append(htmlContent)
              var link = "${process.env.REACT_APP_MEDIA_URL}site/${opts.site}/actualites" + p.categorie.url + p.url;
              $("#share-"+p._id).jsSocials({shares: [{share:"twitter",logo:"fab fa-twitter-square"}, {share:"facebook",logo:"fab fa-facebook-square"}, {share:"googleplus",logo:"fab fa-google-plus-square"},{ share:"linkedin",logo:"fab fa-linkedin"}, {share:"pinterest",logo:"fab fa-pinterest-square"}],url:link ,showCount: false,showLabel: false})
            })
              $("#list-posts-${attr['id']}").append('<ul class="pagination"></ul>')
    
 
              setTimeout(function() {
                var itemsPerPage = parseInt(params.nbr);
                var $pagination = $('.pagination');
                $pagination.html('');
                
                  var $articles = $('.article');
                  var totalPages = Math.ceil($articles.length / itemsPerPage);
              
                  if (totalPages > 1) {
                    for (var i = 1; i <= totalPages; i++) {
                      $pagination.append('<li data-page="' + i + '">' + i + '</li>');
                    }
              
                    showPage(1);
                    
                    $pagination.find('li').on('click', function() {
                      var page = $(this).data('page');
                      showPage(page);
                    });
              
                    function showPage(page) {
                      $articles.hide();
                      var startIndex = (page - 1) * itemsPerPage;
                      var endIndex = startIndex + itemsPerPage;
                      $articles.slice(startIndex, endIndex).show();
                      $pagination.find('li').removeClass('active');
                      $pagination.find('li[data-page="' + page + '"]').addClass('active');
                    }
                  } else {
                    $articles.show();
                  }
              
              }, 1000);
      
        })
       }
    </script>
    
  
    `) 
      },
      
    
    },
    isComponent: (el) => {
      if (el.classList && el.classList.contains("gjs-blog-catgegory")) {
        return {
          type: "Category",
        };
      }
    },
  });

  dc.addType("Article", {
    model: {
      
      defaults: {
        activeOnRender: 1,

       
      },
      init() {

      },
    
    },
    view: {
      getAllChildComponents(component) {
        const children = [];

        // Iterate through the children of the component
        component.components().each((childComponent) => {
          children.push(childComponent);

          // Recursively get children of the child component
          const nestedChildren =
            this.model.view.getAllChildComponents(childComponent);
          children.push(...nestedChildren);
        });

        return children;
      },
      init({ el, model }) {
       
      },
      async doStuff() {

      },

      async onRender({ el, model }) {
        if(opts.type == "model"){
          let children = this.model.view.getAllChildComponents(this.model);
          children.map((c) => {
            c.set("draggable", false);
            c.set("removable", false);
            c.set("copyable", false);
            c.set("resizable", false);
            c.set("droppable", false);
            c.set("editable", false);
            c.set("selectable", false);
            c.set("toolbar", [
             
            ]);
          });
         model.set("draggable", false);
         model.set("removable", false);
         model.set("copyable", false);
         model.set("resizable", false);
         model.set("droppable", false);
         model.set("editable", false);
         model.set("selectable", false);
        }else if(opts.type=="article"){
         let cmp =  editor.getWrapper()
          let allcmp = this.model.view.getAllChildComponents(cmp);
          allcmp.map((c) => {
            c.set("draggable", false);
            c.set("removable", false);
            c.set("copyable", false);
            c.set("resizable", false);
            c.set("droppable", false);
            c.set("editable", false);
            c.set("selectable", false);
 
          });

          let children = this.model.view.getAllChildComponents(this.model);
          children.map((c) => {
            c.set("draggable", true);
            c.set("removable", true);
            c.set("copyable", true);
            c.set("resizable", true);
            c.set("droppable", true);
            c.set("editable", true);
            c.set("selectable", true);

          });
          model.set("draggable", true);
          model.set("removable", true);
          model.set("copyable", false);
          model.set("resizable", true);
          model.set("droppable", true);
          model.set("editable", true);
          model.set("selectable", true);
        }

      },
    },
    isComponent: (el) => {
      if (el  && el.tagName ==="ARTICLE") {
        return {
          type: "Article",
        };
      }
    },
  });
  dc.addType("blogCategories", {
    model: {
      
      defaults: {
        activeOnRender: 1,

       
      },
      init() {

      },
    
    },
    view: {
      getAllChildComponents(component) {
        const children = [];

        // Iterate through the children of the component
        component.components().each((childComponent) => {
          children.push(childComponent);

          // Recursively get children of the child component
          const nestedChildren =
            this.model.view.getAllChildComponents(childComponent);
          children.push(...nestedChildren);
        });

        return children;
      },
      init({ el, model }) {
       
      },
      async doStuff() {

      },

      async onRender({ el, model }) {

          let children = this.model.view.getAllChildComponents(this.model);
          children.map((c) => {
            c.set("draggable", false);
            c.set("removable", false);
            c.set("copyable", false);
            c.set("resizable", false);
            c.set("droppable", false);
            c.set("editable", false);
            c.set("selectable", true);
            
          })
          model.set("draggable", false);
          model.set("removable", false);
          model.set("copyable", false);
          model.set("resizable", false);
          model.set("droppable", false);
          model.set("editable", false);
          model.set("selectable", true);
          model.set("toolbar", [
            {
              attributes: { class: "fa fa-arrow-up" },
              command: "select-parent",
            },
            {
              attributes: { class: "fa fa-arrows" },
              command: "tlb-move",
            },
            
          ]);
        

      },
    },
    isComponent: (el) => {
      if (el && el.classList && el.classList.contains("blogcategorie")) {
        return {
          type: "blogCategories",
        };
      }
    },
  });
};
