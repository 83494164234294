export const modelsMobiles = {
  HeaderModelOne: `  <section data-ref="section-692e0338-ab83-4cb5-9568-3a7491f6f5e5" id="section-fb0d78c2-3e49-4b84-b4e9-449b764cb22d">
  <div ref="Section-62527e3a-7076-4efa-b4f4-60542925074d" id="row-069318b4-388b-48fd-8742-9b261032d1e9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
    <div id="iq1y2" class="gjs-cell">
      <a title="test tarek" id="logo-1d955b76-0aa0-4a95-9d27-a811d11bd43a" href="/" class="logo-gjs"><img id="img-logo-1d955b76-0aa0-4a95-9d27-a811d11bd43a" alt="test tarek" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+" class="logo-img"/></a>
    </div>
    <div id="iopij" class="gjs-cell">
    <span id="il2x-2" class="sidebar-menu-toggle"><i id="icon-c392a8d7-bcf6-44ed-8504-1585f645618e" class="icon-gjs fas fa-align-right">
      </i>
      </span>
    </div>
  </div>
</section>
  <section id="iiyp" data-ref="Section-4759aee1-88c2-482c-a8a3-1b360748b3b7" data-ombre-color="#00000000" data-ombre-type="" data-ombre-size="" data-bg="#fff" class="sidebar-menu SidebarModelOne AnnimationRightToLeft">
    <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-4d13e102-b0c4-486b-a026-b11151b642d9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" draggable="true" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
      <div id="i2di" draggable="true" class="gjs-cell">
        <sapn id="iiw2g" draggable="true" class="sidebar-menu-close">
          <i id="icon-3623c40e-da48-45e3-8765-8139c77729a2" draggable="true" class="icon-gjs fas fa-times">
          </i>
        </sapn>
      </div>
    </div>
    <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-a4078714-6caf-453c-94ff-58d322768aa9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" draggable="true" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
      <div id="ixfbf" draggable="true" class="gjs-cell">
        <div id="irx2f" draggable="true" data-model="vertical" data-bg="rgb(0 0 0 / 0%)" data-seperator-color="#000" data-raduis="{&quot;topLeft&quot;:&quot;0&quot;,&quot;topRight&quot;:&quot;0&quot;,&quot;bottomLeft&quot;:&quot;0&quot;,&quot;bottomRight&quot;:&quot;0&quot;}" data-padding="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;30&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;30&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-text="rgb(0, 0, 0,1)" data-survol-bg="#000000" data-bg-submenu="#eeeeee" data-padding-submenu="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;15&quot;,&quot;right&quot;:&quot;15&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin-submenu="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-submenu-text="rgb(0, 0, 0,1)" data-survol-submenu-bg="#000000" data-style="stroke-effect" data-seperator="" data-align="center" data-logo-width="200px" data-font="unset" data-variant="normal" data-size="{&quot;desktop&quot;:&quot;20&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-color="#000000" data-submenu-font="unset" data-submenu-variant="normal" data-submenu-size="{&quot;desktop&quot;:&quot;16&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-submenu-color="#000000" data-submenu-align="left" class="gjs-navigation stroke-effect">
          <nav id="irw0j" class="gjs-nav d-flex align-items-center">
            <ul class="navbar-nav flex-column">
              <li data-hidden-desktop="false" data-hidden-tablet="false" data-hidden-mobile="false" class="nav-item">
                <a target="_self" id="65423195b54fa6526e89b897-2" href="/" title="Accueil" class="active"> Accueil</a>
              </li>
              <li data-hidden-desktop="true" data-hidden-tablet="false" data-hidden-mobile="false" class="nav-item">
                <a target="_self" id="65423fbdd4e58b6ae3abe8ae-2" href="/sefsdf" title="sefsdf"> sefsdf</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <script>
      $(function(){
        $(".sidebar-menu-toggle").click(function(){
          $(".sidebar-menu").addClass("active");
        }
                                       )
        $(".sidebar-menu-close").click(function(){
          $(".sidebar-menu").removeClass("active");
        }
                                      )
      }
       )
    </script>
    <style>
    #row-f3c2b85e-6ef0-45aa-a637-40aeb62aae37 .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #row-f3c2b85e-6ef0-45aa-a637-40aeb62aae37 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    .appel-gjs i{
      margin-right:10px;
    }
    #row-a410ba80-90df-4394-9ccc-dcbcd2a501b2 .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #row-a410ba80-90df-4394-9ccc-dcbcd2a501b2 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul li::before{
      color:rgb(0, 0, 0);
    }
    #ixod7 ul li a{
      border-top-left-radius:0%;
      border-top-right-radius:0%;
      border-bottom-left-radius:0%;
      border-bottom-right-radius:0%;
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
    }
    #ixod7 ul li a:hover{
      color:rgb(0, 0, 0);
    }
    #ixod7 ul li a.active{
      color:rgb(0, 0, 0);
    }
    #ixod7 ul li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul.dropdown-menu{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(238, 238, 238);
    }
    #ixod7 ul.dropdown-menu li a{
      padding-top:15px;
      padding-bottom:15px;
      padding-right:15px;
      padding-left:15px;
      margin-top:0px;
      margin-bottom:0px;
      margin-right:0px;
      margin-left:0px;
    }
    #ixod7 ul.dropdown-menu li a:hover{
      color:rgb(0, 0, 0);
    }
    #ixod7 ul.dropdown-menu li a.active{
      color:rgb(0, 0, 0);
    }
    #ixod7 ul.dropdown-menu li a:hover::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul.dropdown-menu li a:hover::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul.dropdown-menu li a.active::after{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #ixod7 ul.dropdown-menu li a.active::before{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    .gjs-row{
      display:table;
      width:100%;
    }
    #row-069318b4-388b-48fd-8742-9b261032d1e9 .owl-theme .owl-dots .owl-dot span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    #row-069318b4-388b-48fd-8742-9b261032d1e9 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background-image:initial;
      background-position-x:initial;
      background-position-y:initial;
      background-size:initial;
      background-repeat-x:initial;
      background-repeat-y:initial;
      background-attachment:initial;
      background-origin:initial;
      background-clip:initial;
      background-color:rgb(0, 0, 0);
    }
    @media (max-width: 767px){
      #ipti{
        padding-top:0px;
        padding-right:0px;
        padding-bottom:0px;
        padding-left:0px;
      }
      #section-fb0d78c2-3e49-4b84-b4e9-449b764cb22d{
        padding-top:0px;
        padding-right:0px;
        padding-bottom:0px;
        padding-left:0px;
      }
      #row-069318b4-388b-48fd-8742-9b261032d1e9{
        display:flex!important;
        justify-content:center;
        align-items:center;
        align-content:center;
        padding-top:0px;
        padding-right:0px;
        padding-bottom:0px;
        padding-left:0px;
      }
      #iq1y2{
        width:70%!important;
        vertical-align:middle;
      }
      #logo-1d955b76-0aa0-4a95-9d27-a811d11bd43a{
        position:relative;
        width:auto;
        height:auto;
      }
      #iopij{
        width:30%;
        vertical-align:middle;
        text-align:right;
      }
      #icon-c392a8d7-bcf6-44ed-8504-1585f645618e{
        font-size:45px;
      }
    }
    
    </style>
  </section>

  `,
  HeaderModelTwo: `
  <section data-ref="section-7f377c4a-2ef8-469a-92e8-a094c5bc5efe" id="section-0803df30-73b7-4d95-a493-8e0d52300300">
  <div ref="Section-a42f2cfb-5c22-4406-8bf2-6be115036d47" id="row-5884cea3-21ab-43a7-8a01-0aa58dd9e256" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
    <div class="gjs-cell" id="ibdf">
    <span id="il2x-2" class="sidebar-menu-toggle">  <i id="icon-5f9c2e5a-54eb-4677-a6d6-a2ff93c167e2" class="icon-gjs fas fa-align-justify">
      </i>
      </span>
    </div>
    <div class="gjs-cell" id="isly">
      <a id="logo-54531c9a-8040-49b7-b3f5-1654e95f3776" title="test" href="/" class="logo-gjs"><img id="img-logo-54531c9a-8040-49b7-b3f5-1654e95f3776" alt="test" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+" class="logo-img"/></a>
    </div>
    <div class="gjs-cell" id="iy8o">
      <a data-type-droppabel="true" href="#" role="button" id="inkvf" data-bg="#fff" data-border-size="{&quot;all&quot;:&quot;1&quot;,&quot;top&quot;:&quot;1&quot;,&quot;left&quot;:&quot;1&quot;,&quot;right&quot;:&quot;1&quot;,&quot;bottom&quot;:&quot;1&quot;}" data-border-style="solid" data-border-color="#eee" data-border-type="all" data-icon="{&quot;type&quot;:&quot;icon&quot;,&quot;icon&quot;:&quot;fas fa-mobile-alt&quot;,&quot;unicode&quot;:&quot;\\f3cd&quot;,&quot;size&quot;:&quot;35&quot;,&quot;color&quot;:&quot;#000&quot;}" data-bg-survol="#fff" data-btn-padding="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-btn-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;00&quot;,&quot;right&quot;:&quot;00&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-txt-survol="#000" data-value="" target="_self" data-size="{&quot;desktop&quot;:&quot;50&quot;,&quot;tablet&quot;:&quot;50&quot;,&quot;mobile&quot;:&quot;50&quot;}" data-style="styleFour" data-position="end" class="gjs-btn btn styleFour"></a>
    </div>
  </div>
</section>
  <section id="iiyp" data-ref="Section-4759aee1-88c2-482c-a8a3-1b360748b3b7" data-ombre-color="#00000000" data-ombre-type="" data-ombre-size="" data-bg="#fff" class="sidebar-menu SidebarModelOne AnnimationLeftToRight">
    <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-4d13e102-b0c4-486b-a026-b11151b642d9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" draggable="true" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
      <div id="i2di" draggable="true" class="gjs-cell">
        <sapn id="iiw2g" draggable="true" class="sidebar-menu-close">
          <i id="icon-3623c40e-da48-45e3-8765-8139c77729a2" draggable="true" class="icon-gjs fas fa-times">
          </i>
        </sapn>
      </div>
    </div>
    <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-a4078714-6caf-453c-94ff-58d322768aa9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" draggable="true" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
      <div id="ixfbf" draggable="true" class="gjs-cell">
        <div id="irx2f" draggable="true" data-model="vertical" data-bg="rgb(0 0 0 / 0%)" data-seperator-color="#000" data-raduis="{&quot;topLeft&quot;:&quot;0&quot;,&quot;topRight&quot;:&quot;0&quot;,&quot;bottomLeft&quot;:&quot;0&quot;,&quot;bottomRight&quot;:&quot;0&quot;}" data-padding="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;30&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;30&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-text="rgb(0, 0, 0,1)" data-survol-bg="#000000" data-bg-submenu="#eeeeee" data-padding-submenu="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;15&quot;,&quot;right&quot;:&quot;15&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin-submenu="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-submenu-text="rgb(0, 0, 0,1)" data-survol-submenu-bg="#000000" data-style="stroke-effect" data-seperator="" data-align="center" data-logo-width="200px" data-font="unset" data-variant="normal" data-size="{&quot;desktop&quot;:&quot;20&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-color="#000000" data-submenu-font="unset" data-submenu-variant="normal" data-submenu-size="{&quot;desktop&quot;:&quot;16&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-submenu-color="#000000" data-submenu-align="left" class="gjs-navigation stroke-effect">
          <nav id="irw0j" class="gjs-nav d-flex align-items-center">
            <ul class="navbar-nav flex-column">
              <li data-hidden-desktop="false" data-hidden-tablet="false" data-hidden-mobile="false" class="nav-item">
                <a target="_self" id="65423195b54fa6526e89b897-2" href="/" title="Accueil" class="active"> Accueil</a>
              </li>
              <li data-hidden-desktop="true" data-hidden-tablet="false" data-hidden-mobile="false" class="nav-item">
                <a target="_self" id="65423fbdd4e58b6ae3abe8ae-2" href="/sefsdf" title="sefsdf"> sefsdf</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  <script>
    $(function(){
      $(".sidebar-menu-toggle").click(function(){
        $(".sidebar-menu").addClass("active");
      })
      $(".sidebar-menu-close").click(function(){
        $(".sidebar-menu").removeClass("active");
      })
    })
  </script>
  <style>

  #row-f3c2b85e-6ef0-45aa-a637-40aeb62aae37 .owl-theme .owl-dots .owl-dot span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #row-f3c2b85e-6ef0-45aa-a637-40aeb62aae37 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  .appel-gjs i{
    margin-right:10px;
  }
  #row-a410ba80-90df-4394-9ccc-dcbcd2a501b2 .owl-theme .owl-dots .owl-dot span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #row-a410ba80-90df-4394-9ccc-dcbcd2a501b2 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul li::before{
    color:rgb(0, 0, 0);
  }
  #ixod7 ul li a{
    border-top-left-radius:0%;
    border-top-right-radius:0%;
    border-bottom-left-radius:0%;
    border-bottom-right-radius:0%;
    padding-top:15px;
    padding-bottom:15px;
    padding-right:15px;
    padding-left:15px;
    margin-top:0px;
    margin-bottom:0px;
    margin-right:0px;
    margin-left:0px;
  }
  #ixod7 ul li a:hover{
    color:rgb(0, 0, 0);
  }
  #ixod7 ul li a.active{
    color:rgb(0, 0, 0);
  }
  #ixod7 ul li a:hover::after{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul li a:hover::before{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul li a.active::after{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul li a.active::before{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul.dropdown-menu{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(238, 238, 238);
  }
  #ixod7 ul.dropdown-menu li a{
    padding-top:15px;
    padding-bottom:15px;
    padding-right:15px;
    padding-left:15px;
    margin-top:0px;
    margin-bottom:0px;
    margin-right:0px;
    margin-left:0px;
  }
  #ixod7 ul.dropdown-menu li a:hover{
    color:rgb(0, 0, 0);
  }
  #ixod7 ul.dropdown-menu li a.active{
    color:rgb(0, 0, 0);
  }
  #ixod7 ul.dropdown-menu li a:hover::after{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul.dropdown-menu li a:hover::before{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul.dropdown-menu li a.active::after{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ixod7 ul.dropdown-menu li a.active::before{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #iocaf{
    padding-top:10px;
    padding-right:10px;
    padding-bottom:10px;
    padding-left:10px;
  }
  .gjs-row{
    display:table;
    width:100%;
  }
  #row-069318b4-388b-48fd-8742-9b261032d1e9 .owl-theme .owl-dots .owl-dot span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #row-069318b4-388b-48fd-8742-9b261032d1e9 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #ipti{
    box-shadow:none;
    padding-top:15px;
    padding-bottom:15px;
    padding-right:15px;
    padding-left:15px;
    margin-top:0px;
    margin-bottom:0px;
    margin-right:0px;
    margin-left:0px;
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(255, 255, 255);
  }
  #ipti.sticky{
    padding-top:15px;
    padding-bottom:15px;
    padding-right:15px;
    padding-left:15px;
    margin-top:0px;
    margin-bottom:0px;
    margin-right:0px;
    margin-left:0px;
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(255, 255, 255);
  }
  #ipti .sidebar-menu-toggle{
    font-size:30px;
    color:rgb(0, 0, 0);
  }
  #row-5884cea3-21ab-43a7-8a01-0aa58dd9e256 .owl-theme .owl-dots .owl-dot span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #row-5884cea3-21ab-43a7-8a01-0aa58dd9e256 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(0, 0, 0);
  }
  #inkvf:hover{
    color:rgb(0, 0, 0);
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(255, 255, 255);
  }
  #inkvf::before{
    content:"";
    color:rgb(0, 0, 0);
    font-size:35px;
  }
  #inkvf{
    padding-top:0px;
    padding-bottom:0px;
    padding-left:0px;
    margin-top:0px;
    margin-bottom:0px;
    margin-right:0px;
    margin-left:auto;
    display:flex;
    width:250px;
    justify-content:center;
    align-items:center;
    padding-right:0px;
    border-top-width:1px;
    border-right-width:1px;
    border-bottom-width:1px;
    border-left-width:1px;
    border-top-style:solid;
    border-right-style:solid;
    border-bottom-style:solid;
    border-left-style:solid;
    border-top-color:rgb(238, 238, 238);
    border-right-color:rgb(238, 238, 238);
    border-bottom-color:rgb(238, 238, 238);
    border-left-color:rgb(238, 238, 238);
    border-image-source:initial;
    border-image-slice:initial;
    border-image-width:initial;
    border-image-outset:initial;
    border-image-repeat:initial;
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:rgb(255, 255, 255);
  }
  @media (min-width: 768px) and (max-width: 991px){
    #inkvf{
      width:50px;
    }
  }
  @media (max-width: 767px){
    #ipti{
      padding-top:0px;
      padding-right:0px;
      padding-bottom:0px;
      padding-left:0px;
    }
    #iy8o{
      text-align:right;
      width:25%;
    }
    #inkvf{
      width:50px;
    }
    #row-5884cea3-21ab-43a7-8a01-0aa58dd9e256{
      padding-top:0px;
      padding-right:0px;
      padding-bottom:0px;
      padding-left:0px;
      align-items:center;
      align-content:center;
      display:flex !important;
      justify-content:center !important;
    }
    #section-0803df30-73b7-4d95-a493-8e0d52300300{
      padding-top:0px;
      padding-right:0px;
      padding-bottom:0px;
      padding-left:0px;
    }
    #ibdf{
      width:25%;
    }
    #isly{
      width:50%;
    }
    #logo-54531c9a-8040-49b7-b3f5-1654e95f3776{
      position:relative;
      width:auto;
      height:auto;
    }
  }
  
  
</style>
</section>

  `,

  HeaderModelThree: `
<section data-ref="Section-25b5dc84-5878-4e62-9808-143b3da790ed" id="i8d3s">
      <div ref="Section-7f73c12a-772c-47c7-9b36-23eafb89d82a" id="row-4cfd61d6-08dd-42a3-b8fa-33a73aa7bfa8" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
        <div id="ii2qw" class="gjs-cell">
          <span id="i49h2" class="sidebar-menu-toggle"><i id="ic6ew" class="gjs-icon fas fa-align-left">
            </i></span>
        </div>
        <div id="ibzf" class="gjs-cell">
          <img id="img-logo-5d718a22-2ac0-4312-9def-99ef6b78c7db" alt="test ouss" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+" class="logo-img"/>
        </div>
        <div id="il2x" class="gjs-cell">
          <a data-type-droppabel="true" href="#" role="button" id="id8k5" data-btn-padding="{&quot;top&quot;:&quot;5&quot;,&quot;left&quot;:&quot;5&quot;,&quot;right&quot;:&quot;5&quot;,&quot;bottom&quot;:&quot;5&quot;}" data-btn-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;&quot;,&quot;right&quot;:&quot;&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-value="Contactez-Nous" target="_self" data-model="modelOne" data-style="styleTwo" data-color="rgb(15, 15, 15,1)" data-bordure-color="rgb(19, 18, 18,1)" data-bordure-width="rgb(19, 18, 18,1)" data-bg="rgb(255 ,255 ,255)" data-bg-hover="rgb(255, 255, 255,1)" data-color-hover="rgb(19, 18, 18,1)" data-font="ABeeZee" data-variant="regular" data-width-desktop="40" data-width-tablet="30" data-width-mobile="120" data-height-desktop="55" data-height-tablet="55" data-height-mobile="40" data-size-desktop="14" data-size-tablet="16" data-size-mobile="14" data-icon="fas fa-mobile" data-icon-unicode="\f10b" class="gjs-btn btn styleTwo">Contactez-Nous</a>
        </div>
      </div>
    </section>
    <section id="iiyp" data-ref="Section-4759aee1-88c2-482c-a8a3-1b360748b3b7" data-ombre-color="#00000000" data-ombre-type="" data-ombre-size="" data-bg="#fff" class="sidebar-menu SidebarModelOne AnnimationLeftToRight">
      <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-4d13e102-b0c4-486b-a026-b11151b642d9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" draggable="true" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
        <div id="i2di" draggable="true" class="gjs-cell">
          <sapn id="iiw2g" draggable="true" class="sidebar-menu-close">
            <i id="icon-3623c40e-da48-45e3-8765-8139c77729a2" draggable="true" class="icon-gjs fas fa-times">
            </i>
          </sapn>
        </div>
      </div>
      <div ref="Section-20a886a0-9b78-40f1-8ed7-5fafa3c2d42c" id="row-a4078714-6caf-453c-94ff-58d322768aa9" data-auto="true" data-dot="false" data-nav="true" data-colordots="#000000" data-colordotshover="#000000" data-next="<i class=&quot;fa-solid fa-circle-chevron-right&quot;></i>" data-prev="<i class=&quot;fa-solid fa-circle-chevron-left&quot;></i>" data-margin="20" draggable="true" data-nb_desktop="4" data-nb_tablette="2" data-nb_mobile="1" class="gjs-row">
        <div id="ixfbf" draggable="true" class="gjs-cell">
          <div id="irx2f" draggable="true" data-model="vertical" data-bg="rgb(0 0 0 / 0%)" data-seperator-color="#000" data-raduis="{&quot;topLeft&quot;:&quot;0&quot;,&quot;topRight&quot;:&quot;0&quot;,&quot;bottomLeft&quot;:&quot;0&quot;,&quot;bottomRight&quot;:&quot;0&quot;}" data-padding="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;30&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;30&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-text="rgb(0, 0, 0,1)" data-survol-bg="#000000" data-bg-submenu="#eeeeee" data-padding-submenu="{&quot;top&quot;:&quot;15&quot;,&quot;left&quot;:&quot;15&quot;,&quot;right&quot;:&quot;15&quot;,&quot;bottom&quot;:&quot;15&quot;}" data-margin-submenu="{&quot;top&quot;:&quot;0&quot;,&quot;left&quot;:&quot;0&quot;,&quot;right&quot;:&quot;0&quot;,&quot;bottom&quot;:&quot;0&quot;}" data-survol-submenu-text="rgb(0, 0, 0,1)" data-survol-submenu-bg="#000000" data-style="stroke-effect" data-seperator="" data-align="center" data-logo-width="200px" data-font="unset" data-variant="normal" data-size="{&quot;desktop&quot;:&quot;20&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-color="#000000" data-submenu-font="unset" data-submenu-variant="normal" data-submenu-size="{&quot;desktop&quot;:&quot;16&quot;,&quot;tablet&quot;:&quot;14&quot;,&quot;mobile&quot;:&quot;14&quot;}" data-submenu-color="#000000" data-submenu-align="left" class="gjs-navigation stroke-effect">
            <nav id="irw0j" class="gjs-nav d-flex align-items-center">
              <ul class="navbar-nav flex-column">
                <li data-hidden-desktop="false" data-hidden-tablet="false" data-hidden-mobile="false" class="nav-item">
                  <a target="_self" id="65423195b54fa6526e89b897-2" href="/" title="Accueil" class="active"> Accueil</a>
                </li>
                <li data-hidden-desktop="true" data-hidden-tablet="false" data-hidden-mobile="false" class="nav-item">
                  <a target="_self" id="65423fbdd4e58b6ae3abe8ae-2" href="/sefsdf" title="sefsdf"> sefsdf</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <script>
        $(function(){
          $(".sidebar-menu-toggle").click(function(){
            $(".sidebar-menu").addClass("active");
          }
                                         )
          $(".sidebar-menu-close").click(function(){
            $(".sidebar-menu").removeClass("active");
          }
                                        )
        }
         )
      </script>
    </section>
    <style>
    #ipti{
        box-shadow:none;
        padding-top:15px;
        padding-bottom:15px;
        padding-right:15px;
        padding-left:15px;
        margin-top:0px;
        margin-bottom:0px;
        margin-right:0px;
        margin-left:0px;
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(255, 255, 255);
      }
      #ipti.sticky{
        padding-top:15px;
        padding-bottom:15px;
        padding-right:15px;
        padding-left:15px;
        margin-top:0px;
        margin-bottom:0px;
        margin-right:0px;
        margin-left:0px;
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(255, 255, 255);
      }
      #row-4cfd61d6-08dd-42a3-b8fa-33a73aa7bfa8 .owl-theme .owl-dots .owl-dot span{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #row-4cfd61d6-08dd-42a3-b8fa-33a73aa7bfa8 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #il2x{
        width:33%;
        text-align:right;
      }
      #ibzf{
        width:33%;
        text-align:center;
      }
      #img-logo-5d718a22-2ac0-4312-9def-99ef6b78c7db{
        width:250px;
      }
      .adresse-gjs i{
        font-size:25px;
      }
      .appel-gjs i{
        margin-right:10px;
      }
      #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot span{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #row-4d13e102-b0c4-486b-a026-b11151b642d9 .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul li::before{
        color:rgb(0, 0, 0);
      }
      #ibo5 ul li a{
        border-top-left-radius:0%;
        border-top-right-radius:0%;
        border-bottom-left-radius:0%;
        border-bottom-right-radius:0%;
        padding-top:15px;
        padding-bottom:15px;
        padding-right:30px;
        margin-top:0px;
        margin-bottom:0px;
        margin-right:0px;
        margin-left:30px;
        font-family:unset;
        color:rgb(0, 0, 0);
        text-align:left;
        font-weight:normal;
        font-size:20px;
        line-height:20px;
        padding-left:0px;
      }
      #ibo5 ul li a:hover{
        color:rgb(0, 0, 0);
      }
      #ibo5 ul li a.active{
        color:rgb(0, 0, 0);
      }
      #ibo5 ul li a:hover::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul li a:hover::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul li a.active::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul li a.active::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul.dropdown-menu{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(238, 238, 238);
      }
      #ibo5 ul.dropdown-menu li a{
        padding-top:15px;
        padding-bottom:15px;
        padding-right:15px;
        padding-left:15px;
        margin-top:0px;
        margin-bottom:0px;
        margin-right:0px;
        margin-left:0px;
        font-family:unset;
        color:rgb(0, 0, 0);
        text-align:left;
        font-weight:normal;
        font-size:16px;
        line-height:16px;
      }
      #ibo5 ul.dropdown-menu li a:hover{
        color:rgb(0, 0, 0);
      }
      #ibo5 ul.dropdown-menu li a.active{
        color:rgb(0, 0, 0);
      }
      #ibo5 ul.dropdown-menu li a:hover::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul.dropdown-menu li a:hover::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul.dropdown-menu li a.active::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ibo5 ul.dropdown-menu li a.active::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #i2di{
        width:50%;
        vertical-align:middle;
        padding-top:0px;
        padding-right:0px;
        padding-bottom:0px;
        padding-left:0px;
        text-align:right;
      }
      #irx2f{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgba(0, 0, 0, 0);
        display:flex !important;
        justify-content:center !important;
      }
      #ixfbf{
        width:50%;
        vertical-align:middle;
      }
      #igl2fw .owl-theme .owl-dots .owl-dot span{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #igl2fw .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #icon-3623c40e-da48-45e3-8765-8139c77729a2{
        font-size:45px;
      }
      #irx2f ul li::before{
        color:rgb(0, 0, 0);
      }
      #irx2f ul li a{
        border-top-left-radius:0%;
        border-top-right-radius:0%;
        border-bottom-left-radius:0%;
        border-bottom-right-radius:0%;
        padding-top:15px;
        padding-bottom:15px;
        padding-right:30px;
        padding-left:0px;
        margin-top:0px;
        margin-bottom:0px;
        margin-right:0px;
        margin-left:30px;
        font-family:unset;
        color:rgb(0, 0, 0);
        text-align:left;
        font-weight:normal;
        font-size:20px;
        line-height:20px;
      }
      #irx2f ul li a:hover{
        color:rgb(0, 0, 0);
      }
      #irx2f ul li a.active{
        color:rgb(0, 0, 0);
      }
      #irx2f ul li a:hover::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #irx2f ul li a:hover::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #irx2f ul li a.active::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #irx2f ul li a.active::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #irx2f ul.dropdown-menu{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(238, 238, 238);
      }
      #irx2f ul.dropdown-menu li a{
        padding-top:15px;
        padding-bottom:15px;
        padding-right:15px;
        padding-left:15px;
        margin-top:0px;
        margin-bottom:0px;
        margin-right:0px;
        margin-left:0px;
      }
      #irx2f ul.dropdown-menu li a:hover{
        color:rgb(0, 0, 0);
      }
      #irx2f ul.dropdown-menu li a.active{
        color:rgb(0, 0, 0);
      }
      #irx2f ul.dropdown-menu li a:hover::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #irx2f ul.dropdown-menu li a:hover::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #irx2f ul.dropdown-menu li a.active::after{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #irx2f ul.dropdown-menu li a.active::before{
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(0, 0, 0);
      }
      #ii2qw{
        width:33%;
        text-align:right;
      }
      #id8k5{
        padding-top:5px;
        padding-bottom:5px;
        padding-right:5px;
        padding-left:5px;
        margin-top:0px;
        margin-bottom:0px;
        margin-right:auto;
        margin-left:auto;
        display:flex;
        justify-content:center;
        align-items:center;
        color:rgb(15, 15, 15);
        font-family:ABeeZee;
        font-size:14px;
        width:40px;
        height:55px;
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(255, 255, 255);
        border-top-width:1px;
        border-right-width:1px;
        border-bottom-width:1px;
        border-left-width:1px;
        border-top-color:rgb(19, 18, 18);
        border-right-color:rgb(19, 18, 18);
        border-bottom-color:rgb(19, 18, 18);
        border-left-color:rgb(19, 18, 18);
      }
      #id8k5::before{
        display:none;
      }
      #id8k5:hover{
        color:rgb(19, 18, 18);
        background-image:initial;
        background-position-x:initial;
        background-position-y:initial;
        background-size:initial;
        background-repeat-x:initial;
        background-repeat-y:initial;
        background-attachment:initial;
        background-origin:initial;
        background-clip:initial;
        background-color:rgb(255, 255, 255);
      }
     
      #id8k5::before{
        font-weight:900;
        content:"";
        margin-right:10px;
        display:block;
      }
      #ipti .sidebar-menu-toggle{
        font-size:30px;
        color:rgb(32, 31, 31);
      }
     
      #id8k5::before{
        display:none;
      }
      @media (min-width: 768px) and (max-width: 991px){
        #ibo5 ul li a{
          font-size:14px;
          line-height:14px;
        }
        #irx2f ul li a{
          font-size:14px;
          line-height:14px;
        }
       
        #id8k5{
          font-size:16px;
          width:30px;
          height:55px;
        }
      }
      @media (max-width: 767px){
        #ipti{
          height:auto;
          min-height:auto;
          padding-top:0px;
          padding-right:0px;
          padding-bottom:0px;
          padding-left:0px;
        }
        #ibo5 ul li a{
          font-size:14px;
          line-height:14px;
        }
        #ibo5 ul.dropdown-menu li a{
          font-size:14px;
          line-height:14px;
        }
        #irx2f ul li a{
          font-size:14px;
          line-height:14px;
        }
        #il2x{
          width:20px;
        }
        #row-4cfd61d6-08dd-42a3-b8fa-33a73aa7bfa8{
          padding-top:0px;
          padding-right:0px;
          padding-bottom:0px;
          padding-left:0px;
          width:100%;
          justify-content:center;
          align-items:center;
          align-content:center;
          display:flex;
        }
        #i8d3s{
          padding-top:0px;
          padding-right:0px;
          padding-bottom:0px;
          padding-left:0px;
        }
        #ibzf{
          width:20px;
        }
        #ii2qw{
          text-align:left;
          width:20px;
        }
        #img-logo-5d718a22-2ac0-4312-9def-99ef6b78c7db{
          width:120px;
        }
      
        #id8k5{
          font-size:14px;
          width:120px;
          height:40px;
        }
      }
      
    </style>
`,
};
